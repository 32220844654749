@import 'variables';

.title {
  margin-top: 0;
  margin-bottom: rem(16);
  color: $dark-blue;
}

.reward-title {
  color: $dark-blue;
  margin: rem(32) 0 rem(16);
  @include desktop-up() {
    margin: 0 0 rem(16);
  }
}
