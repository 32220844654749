@import 'variables';

.dialogTitle {
  display: flex;
  position: relative;
  flex: 0 0 rem(20);
  align-items: center;
  justify-content: center;
}

.header {
  flex: 1 0 rem(20);
  font-size: rem(16);
  font-weight: $font-bold;
  text-align: center;
  line-height: rem(20);
  margin: 0;
  align-self: center;
  padding: 0 rem(20);
}

.dialogContent {
  display: flex;
  flex: 1 1 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: rem(24) 0;
}

.dialogActions {
  display: flex;
  flex: 0 0 rem(40);
  justify-content: center;
}

.backButton {
  position: absolute;
  padding: rem(12);
  left: rem(-12);
  top: rem(-14);
  fill: $dark-blue;
}

.iconButton {
  position: absolute;
  padding: rem(12);
  right: rem(-12);
  top: rem(-14);
  fill: $dark-blue;
}

.dialog {
  padding: rem(16);
  width: 100%;
  max-width: calc(100% - 2rem);
  margin: 0;

  @include tablet-portrait-up {
    padding: rem(24);
    max-width: rem(470);
  }
}

.container {
  padding: 20px 20px;
}

.iconButton {
  padding: rem(12);
}
