@import 'variables';

.container {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;
  min-width: 0;
}

.icon-default {
  flex: 0 0 rem(24);
  margin-right: rem(12);
  display: flex;
}

.text-regular {
  flex: 1 1;
  white-space: break-spaces;
  @include text($white, $font-size-l, $line-height-l);
}

.text-bold {
  flex: 1 1;
  white-space: break-spaces;
  @include text($white, $font-size-l, $line-height-l, $font-bold);
}

.text-ellipsis {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include text($white, $font-size-l, $line-height-l);
}

.text-bold-ellipsis {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include text($white, $font-size-l, $line-height-l, $font-bold);
}
