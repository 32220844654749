@import 'variables';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  margin-bottom: rem(4);
  margin-left: rem(20);
  display: flex;
}
