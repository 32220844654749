@import 'variables';

.form {
  display: flex;
  align-items: center;
}

.textInput {
  margin-left: rem(10);
  margin-right: rem(10);
}

.fullPage {
  flex-direction: column;
  & button {
    margin-top: $gutter;
  }
}
