@import 'variables';

.container {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-self: center;
  flex-direction: row;
  background: $dark-blue;
  align-items: center;
  padding: 0;
}
