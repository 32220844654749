@import 'variables';

.form {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(#{$gutter} * 2);
  @include tablet-portrait-up {
    gap: $gutter;
  }
}
