@import 'variables';

.errorSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: rem(24) 0;
}

.button {
  margin-top: rem(24);
}

.article {
  width: 100%;
}

.spinnerSection {
  height: rem(120);
  display: flex;
  align-items: center;
  justify-content: center;
}
