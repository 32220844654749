$rem-base: 16 !default;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function rem($pixels) {
  $rem-vals: ();
  @each $val in $pixels {
    $val-in-rems: strip-unit($val) / $rem-base * 1rem;
    $rem-vals: append($rem-vals, $val-in-rems);
  }

  @if length($rem-vals) == 1 {
    // return a single value instead of a list,
    // so it can be used in calculations
    @return nth($rem-vals, 1);
  } @else {
    @return $rem-vals;
  }
}
