@import 'variables';

.icon {
  margin: $gutter auto;
  width: rem(48);
  height: rem(48);
}

.helper {
  margin-top: $gutter;
}
