@import 'variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop-up {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.main,
.right {
  padding: $gutter;
}

.main {
  flex: 1;
  width: 100%;
  max-width: $content-max-width;
  @include desktop-up {
    flex: 0 1 rem(730);
    width: 100%;
    max-width: none;
  }
}

.right {
  flex: 1;
  width: 100%;
  max-width: $content-max-width;
  @include desktop-up {
    max-width: $single-sidebar-width;
  }
}
