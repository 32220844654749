@import 'variables';

.dialogContent {
  overflow: visible;
}

.buttonSection {
  margin: rem(8) 0;
}

.header {
  @include text($white, $font-size-l, $line-height-l, $font-bold);
  margin: 0;
  margin-bottom: rem(8);
}

.paragraph {
  margin: 0;
  margin-bottom: rem(24);
  @include text($white, $font-size-m, $line-height-l, $font-regular, center);
}

.messageBox {
  margin-top: 0;
  margin-bottom: rem(14);
  width: 100%;
}

.buttonContainer {
  text-align: right;
  margin-top: rem(32);
  display: flex;
  justify-content: flex-end;
}

.icon {
  color: $white;
  position: absolute;
  top: rem(16);
  left: rem(16);
}
