@import 'variables';

.topHeader {
  margin: 0;
  @include text(
    $dark-blue,
    $font-size-xl,
    $line-height-xxl,
    $font-bold,
    'center'
  );
}

.link {
  margin-top: rem(32);
  margin-bottom: rem(-24);
  align-self: flex-end;
}

.dialogContent {
  width: 100%;
}

.listItem {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.list {
  width: 100%;
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.section {
  display: flex;
  flex-direction: column;
}

.small {
  margin-top: rem(10);
  display: inline-block;
}
