@import 'variables';

.container {
  background-color: $white;
  padding: calc(2 * #{$gutter});
  border-radius: rem(16);
}

.cta {
  font-size: $font-size-xxl;
  color: $dark-blue;
}
