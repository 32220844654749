@import 'variables';

.figure {
  display: flex;
  background-color: white;
  color: $dark-blue;
  font-size: rem(14);
  line-height: rem(20);
  border-radius: 50%;
  margin: 0;
  height: rem(28);
  width: rem(28);
  justify-content: center;
}

.span {
  align-self: center;
}
