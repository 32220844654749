@import 'variables';

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.gridContainer {
  width: 100%;
  flex-grow: 1;
}

.disclaimer {
  margin: $gutter 0;
  color: $dark-blue;
  display: flex;
  flex-direction: column;
}
