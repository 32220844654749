@import 'variables';

.container {
  padding: rem(16) 0;
  border-top: rem(1) solid $dark-blue;
}

.icon {
  color: $dark-blue;
}

.content {
  color: $dark-blue;
}
