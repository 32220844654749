@import 'variables';

.container {
  background-color: $dark-blue;
  padding: rem(18);
  margin: rem(24) 0;
  border-radius: rem(4);
  position: relative;
}

.cancelIcon {
  position: absolute;
  top: rem(20);
  right: rem(20);
  padding: 0;
  background-color: transparent;
}

.icon {
  position: absolute;
  top: rem(20);
  left: rem(20);
}

.section {
  display: block;
  padding: 0 rem(30);
  @include text($white, $font-size-l, $line-height-l, $font-regular);
}

.header {
  margin: 0;
  margin-bottom: rem(8);
  @include text($white, $font-size-l, $line-height-l, $font-bold);
  display: block;
}

.span {
  align-self: flex-end;
}
