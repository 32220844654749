@import 'variables';

.button {
  background: transparent;
  border: none;
  outline: none;
  font-weight: $font-regular;
  cursor: pointer;
  padding: 0 rem(18);
  width: 100%;
}

.button:first-child > div {
  border-top: rem(1) solid transparent;
}
