@import 'variables';

.title {
  font-size: $font-size-xl;
  color: $dark-blue;
  margin: 0;
}

.buttonsContainer {
  padding-bottom: $gutter;
  margin-left: calc(-#{$gutter} / 2);
  display: flex;
  align-items: center;
  gap: $gutter;
}

// .button {
//   margin: 0 calc(#{$gutter}/ 2);
// }

.fullWidthButtonsContainer {
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.rightAligned {
  @include tablet-landscape-up {
    margin-left: auto;
  }
}
