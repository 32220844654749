@import 'variables';

.wrapper {
  position: relative;
}

.iconWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress {
  color: $white;
}
