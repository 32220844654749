@import 'variables';

.MuiDataGrid-root .MuiDataGrid-colCellTitle {
  font-weight: $font-bold;
}

.MuiDataGrid-iconSeperator {
  color: $light-gray;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-toolbar {
  svg {
    color: $dark-blue;
  }
  & button:hover {
    svg {
      color: $white;
    }
  }
  & button:first-child {
    display: none;
  }
  & button:last-child {
    display: none;
  }
  span.MuiButton-label {
    font-size: rem(16);
  }
}
