@import 'variables';

.widgetContainer {
  background: $white;
  width: 100%;
  max-width: rem(478);
  margin-bottom: rem(16);
}

.wrapperContainer {
  padding-bottom: rem(170);
}
