@import 'variables';

.MuiTableCellRoot {
  color: $dark-blue;
}

.MuiTableCellRootHeader {
  font-weight: 700;
  color: $dark-blue;
}
