@import 'variables';

.icon {
  width: rem(32);
  height: rem(32);
  display: flex;
  align-items: center;
  justify-content: center;
  @include tablet-landscape-up {
    margin-right: rem(8);
  }
}

.iconTextContentWrapper {
  display: none;
  @include tablet-landscape-up {
    display: inline;
  }
}

.link {
  margin: 0 rem(16) 0 0;
  @include tablet-landscape-up {
    margin: 0 rem(24) 0 0;
  }
  &:disabled {
    cursor: default;
  }
}

.MuiSvgIconRoot {
  color: $white;
}
