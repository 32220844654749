@import 'variables';

.header {
  width: 100%;
  background: $dark-blue;
  height: $header-height;
  position: fixed;
  z-index: $header-z-index;
  box-shadow: $header-box-shadow;
}

.container {
  max-width: $max-width;
  margin: auto;
  height: 100%;
  display: flex;
  padding: 0 $gutter;
  @include tablet-portrait-up {
    padding: 0 $gutter * 2;
  }
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.containerRight {
  display: flex;
}
