@import 'variables';

.title {
  font-size: $font-size-xxl;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: calc(2 * #{$gutter});
  text-align: center;
  max-width: rem(532);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: rem(160);
  padding: $gutter calc(2 * #{$gutter});
}

.actions {
  padding-bottom: $gutter;
  display: flex;
  align-items: center;
  justify-content: center;
}
