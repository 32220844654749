@import 'variables';

.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $dark-blue;
  background-color: $light-gray;
  text-align: center;
}

.title,
.subtitle {
  margin: 0;
  margin: rem(24) auto;
  width: rem(640);
  max-width: 90%;
}

.contentContainer {
  width: rem(640);
  max-width: 90%;
  margin: 0 auto;
}

.content {
  margin-top: rem(16);
}

.linksContainer {
  width: rem(520);
  max-width: 90%;
  display: flex;
  justify-content: space-around;
  margin: rem(24) auto;
}

.browserLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $dark-blue;
  margin: 0 rem(16);
}

.imgLink {
  width: rem(48);
}
