@import 'variables';

.section {
  align-self: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: white;
  border-radius: rem(16);
  padding: rem(32) rem(31) rem(32) rem(32);
  margin-bottom: rem(24);
}
