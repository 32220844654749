@import 'variables';

.container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - (#{$header-height} + (#{$gutter} * 4)));

  @include desktop-up {
    justify-content: flex-start;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $gutter;
  flex: 1 1 rem(730);
}
