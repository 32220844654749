@import 'variables';

.tableLoaderContainer {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
