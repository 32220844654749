@import 'variables';

.semitransparent {
  background-color: $dark-blue;
  border: none;
  padding: rem(16);
  box-shadow: none;
  &:hover {
    background-color: rgba($dark-blue, 0.5);
    box-shadow: none;
  }
}

.emptyButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
}
