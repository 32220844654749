@import 'variables';

.span {
  display: flex;
  align-items: center;
  margin-top: rem(12);
}

.fileNameSpan {
  margin-left: rem(10);
}
