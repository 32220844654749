@import 'variables';

.container-xs {
  margin-bottom: rem(16);
}

.container-s {
  margin-bottom: rem(24);
}

.container-m {
  margin-bottom: rem(32);
}

@mixin widget {
  background: $white;
  color: $dark-blue;
  border-radius: rem(16);
  position: relative;
}

.widget {
  @include widget;
}

.widget-s {
  @include widget;
  padding: rem(16);
}

.widget-m {
  @include widget;
  padding: rem(16);
  @include tablet-portrait-up {
    padding: rem(24);
  }
}

.widget-transparent {
  background-color: transparent;
}

.widget-white {
  background-color: $white;
}

.widget-shop {
  @include widget;
}
