@import 'variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.error {
  color: $error;
  font-size: rem(14);
  line-height: rem(18);
  margin-bottom: rem(-18);
}
