@import 'variables';

.link {
  display: block;
  padding: 0 rem(18);
}

.link:first-child > div {
  border-top: rem(1) solid transparent;
}

.selectedLink {
  background-color: $lighter-blue;
}
