@import 'variables';

.section {
  margin-top: rem(20);
  margin-bottom: rem(10);
  display: flex;
  flex-direction: column;
}

.paragraph {
  color: $error;
  font-size: rem(14);
  line-height: rem(18);
}

.titleParagraph {
  margin-bottom: rem(5);
  align-self: center;
}

.datePickerSelect {
  border: none;
  border-bottom: 1px solid $dark-blue;
  color: $dark-blue;
  margin: 0 2px;
  &:focus {
    outline-color: $dark-blue;
  }
}

// .hide {
//   display: none;
// }
