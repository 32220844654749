@import 'variables';

.header {
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: rem(32);
  @include text($dark-blue, rem(28), rem(40), $font-bold, center);
}

.container {
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: rem(32);
  margin-bottom: rem(26);
}

.loginErrorBox {
  width: 100%;
  margin-top: 0;
  text-align: left;
}
