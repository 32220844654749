@import 'variables';

.wrapper {
  background: url('/assets/bg.jpg') no-repeat center fixed;
  background-size: cover;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.section {
  padding: rem(32);
}

.header {
  margin: 0;
  letter-spacing: normal;
  @include text($dark-blue, rem(32), $line-height-xxxl, $font-bold, center);
}
