@import 'variables';

.icon {
  width: rem(72);
  height: rem(72);
  margin: 0 0 rem(24);
}

.message {
  margin: rem(8) 0 rem(2);
}

.header {
  font-weight: $font-bold;
  margin: 0;
}
