@import 'variables';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}
