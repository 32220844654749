@import 'variables';

.wrapper {
  position: fixed;
  background-color: $dark-blue;
  width: 100%;
  height: 100%;
}

.innerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress {
  color: $white;
}
