@import 'utils/index.scss';

/* Color Pallette */
$dark-blue: #36495e;
$light-blue: #647d9b;
$lighter-blue: #9cb7d6;
$light-gray: #f0f0f0;
$black: #303436;
$white: #ffffff;
$green: #2db993;
$orange: #f7ab3f;
$cyan: #02bbf0;

// Action
$action: #fff;

// Status
$success: #009413;
$error: #f44336;
$warning: #f9e318;
$warning-dark: #ff5800;
$warning-light: #fce400;

// Categories - Gradients
$energy: linear-gradient(90deg, rgb(198, 2, 13) 0%, rgb(249, 0, 10) 100%);
$gas: linear-gradient(90deg, rgb(10, 147, 221) 0%, rgb(2, 184, 235) 100%);
$eco: linear-gradient(90deg, rgb(1, 143, 108) 0%, rgb(8, 215, 170) 100%);

// Greyscale
$gray: #84878e;
$gray-medium: #e1e1df;

$purple-light: #d4d6ed;

/* Typography */
$font-size-s: rem(12);
$font-size-m: rem(14);
$font-size-l: rem(16);
$font-size-xl: rem(18);
$font-size-xxl: rem(20);
$font-size-xxxl: rem(40);
$font-size-xxxxl: rem(48);

$line-height-s: rem(16);
$line-height-m: rem(18);
$line-height-l: rem(20);
$line-height-xl: rem(22);
$line-height-xxl: rem(24);
$line-height-xxxl: rem(44);
$line-height-xxxxl: rem(52);

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

/* Useful sizes */
$gutter: rem(16);
$max-width: rem(1800);
$header-height: rem(64);
$sidebar-width: rem(302);
$single-sidebar-width: rem(406);
$content-max-width: rem(564);
$authorized-container-padding: $gutter * 2;
$authorized-container-padding-top: $header-height +
  $authorized-container-padding;

/* z-indexes */
$bubble-z-index: 3;
$header-z-index: 99;
$overlay-z-index: 100;

/* box-shadow */
$header-box-shadow: 0px 2px 4px 0px rgba($dark-blue, 0.5);

/* media query variables */
$phone-max: 599px;
$tablet-portrait-min: 600px;
$tablet-landscape-min: 900px;
$desktop-min: 1200px;
$big-desktop-min: 1800px;
