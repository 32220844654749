@import 'variables';

.container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.link {
  display: flex;
  align-items: center;
}
