@import 'variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button {
  margin: $gutter;
}

.pending {
  display: flex;
  align-items: center;
  justify-content: center;
}
