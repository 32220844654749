@import 'variables';

.inline {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: $gutter;
}

.inlineInput {
  padding-right: $gutter;
}

.switch {
  margin-left: rem(48);
  margin-right: rem(-32);
}

.switchContainer {
  margin-left: rem(-48);
}
