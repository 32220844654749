@import 'variables';

.MuiAutoCompleteRoot {
  width: calc(100% - 4px);
}

.MuiListboxComponent {
  background-color: $dark-blue;
  color: $white;
  height: rem(160);
}
