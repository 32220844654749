@import 'variables';

.topHeader {
  margin: 0;
  @include text(
    $dark-blue,
    $font-size-xl,
    $line-height-xxl,
    $font-bold,
    center
  );
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.submitButton {
  margin-top: rem(32);
  align-self: flex-end;
}

.registerErrorBox {
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}

.dialogContent {
  padding-bottom: 0;
}
