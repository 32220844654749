@mixin text(
  $color: $dark-blue,
  $size: $font-size-m,
  $lh: $line-height-m,
  $weight: $font-regular,
  $text-align: left
) {
  color: $color;
  font-size: $size;
  line-height: $lh;
  font-weight: $weight;
  text-align: $text-align;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin phone-only {
  @media (max-width: $phone-max) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: $tablet-portrait-min) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $desktop-min) {
    @content;
  }
}

@mixin big-desktop-up {
  @media (min-width: $big-desktop-min) {
    @content;
  }
}
